<template>
  <div class="battery-wrap">
    <el-tree
      :data="treeData"
      :props="defaultProps"
      accordion
      @node-click="handleNodeClick"
      class="tree"
    >
    </el-tree>
    <div class="content">
      <div class="title">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="设备编号">
            <el-input
              v-model="searchForm.haiLiDeviceInfo"
              clearable
              placeholder="请输入设备编号/电池二维码/通讯板编号"
              style="width:300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品型号">
            <ProductNo
              v-model="searchForm.productNo"
              clearable
              ref="product_no"
              filterable
              placeholder="请选择产品型号"
            >
            </ProductNo>
          </el-form-item>
          <el-form-item label="工作状态">
            <el-select
              ref="searchFormWorkStatus"
              v-model="searchForm.workStatus"
              clearable
              placeholder="请选择工作状态"
            >
              <el-option
                v-for="opt in WORK_STATUS"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在线状态">
            <el-select
              ref="searchFormStatus"
              v-model="searchForm.status"
              clearable
              placeholder="请选择在线状态"
            >
              <el-option
                v-for="opt in STATUS"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="BMS类型">
            <el-select
              ref="searchFormBmsType"
              v-model="searchForm.bmsType"
              clearable
              placeholder="请选择BMS类型"
            >
              <el-option
                v-for="opt in BMS_BOARD_TYPE"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="激活状态">
            <el-select
              ref="searchFormActivityStatus"
              v-model="searchForm.activityStatus"
              clearable
              placeholder="请选择激活状态"
            >
              <el-option
                v-for="opt in ACTIVITY_STATUS"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电池状态">
            <el-select
              ref="searchFormBatteryStatus"
              v-model="searchForm.batteryStatus"
              clearable
              placeholder="请选择电池状态"
            >
              <el-option
                v-for="opt in BATTERY_STATUS"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="定位状态">
            <el-select
              ref="searchFormPositionStatus"
              v-model="searchForm.positionStatus"
              clearable
              placeholder="请选择定位状态"
            >
              <el-option
                v-for="opt in POSITION_STATUS"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择代理/门店">
            <AgentTree
              v-model="agentNo"
              clearable
              :props="angetProps"
              filterable
              placeholder="选择代理/门店"
            ></AgentTree>
          </el-form-item>

          <el-form-item label="租赁状态">
            <el-select
              ref="searchFormLeaseStatus"
              v-model="searchForm.leaseStatus"
              clearable
              placeholder="请选择租赁状态"
            >
              <el-option
                v-for="opt in LEASE_STATUS"
                :key="opt.value"
                :value="opt.value"
                :label="opt.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="放电开关">
            <el-select
              ref="searchFormSwitchStatus"
              v-model="searchForm.switchStatus"
              clearable
              placeholder="请选择放电开关"
            >
              <el-option :value="0" label="关"></el-option>
              <el-option :value="1" label="开"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <div class="fr mb20">
          <el-button type="primary" @click="disEquipment">分配设备</el-button>
        </div>
      </div>
      <div>
        <el-table
          :data="list"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column fixed type="selection" width="55"> </el-table-column>
          <el-table-column
            fixed
            prop="deviceNo"
            min-width="210"
            label="设备编号"
          >
            <template slot="header">
              <p>设备编号</p>
              <p>电池二维码</p>
              <p>通讯板编号</p>
            </template>
            <template slot-scope="{ row }">
              <p><span class="c9">设备编号:</span> {{ row.haiLiDeviceNo }}</p>
              <p><span class="c9">电池二维码:</span> {{ row.linkNo }}</p>
              <p><span class="c9">通讯板编号:</span> {{ row.deviceId }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="agentStr" label="代理商" min-width="120">
          </el-table-column>
          <el-table-column prop="shopStr" label="门店" min-width="120">
          </el-table-column>
          <el-table-column prop="activityStatus"  label="激活状态">
            <template slot-scope="{ row }">
              {{ $changeObj(ACTIVITY_STATUS)[row.activityStatus] }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="在线状态" >
            <template slot-scope="{ row }">
              {{ $changeObj(STATUS)[row.status] }}
            </template>
          </el-table-column>
          <el-table-column prop="productStr" label="产品型号">
          </el-table-column>
          <el-table-column prop="bmsType" label="BMS板">
            <template slot-scope="{ row }">
              {{ $changeObj(BMS_BOARD_TYPE)[row.bmsType] }}
            </template>
          </el-table-column>
          <el-table-column prop="workStatus" label="工作状态" >
            <template slot-scope="{ row }">
              {{ $changeObj(WORK_STATUS)[row.workStatus] }}
            </template>
          </el-table-column>
          <el-table-column prop="leaseStatus" label="租赁状态" >
            <template slot-scope="{ row }">
              {{ $changeObj(LEASE_STATUS)[row.leaseStatus] }}
            </template>
          </el-table-column>
          <el-table-column prop="leaseEndTime" label="租赁到期时间" width="150">
          </el-table-column>
          <el-table-column prop="batteryStatus" label="电池状态" >
            <template slot-scope="{ row }">
              {{ $changeObj(BATTERY_STATUS)[row.batteryStatus] }}
            </template>
          </el-table-column>
          <el-table-column prop="publishStatus" label="发布状态" >
            <template slot-scope="{ row }">
              {{ $changeObj(PUBLISH_STATUS)[row.publishStatus] }}
            </template>
          </el-table-column>
          <el-table-column prop="soc" label="电池电量" >
            <template slot-scope="{ row }"> {{ row.soc || '0%' }} </template>
          </el-table-column>
          <!-- TO DO -->
          <!-- <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">详情</el-button>
            <el-button type="text" @click="edit(scope.row)">查看轨迹</el-button>
          </template>
        </el-table-column> -->
        </el-table>
      </div>
      <div class="fr mt20">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="beginIndex"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="分配设备" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="选择分配对象">
          <el-cascader
            v-model="objectNos"
            :options="objectData"
            :props="props"
            clearable
            style="width: 300px"
            placeholder="请选择分配对象"
          >
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="distributeBattery">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLoanList,
  createDeviceRelationList,
  queryUnitLoanReceiptTree
} from "@/api/fundingParty";
import AgentTree from "@/components/AgentTree";
import ProductNo from "@/components/ProductNo";

import {
  PRODUCT_TYPE,
  STATUS,
  WORK_STATUS,
  BMS_BOARD_TYPE,
  ACTIVITY_STATUS,
  POSITION_STATUS,
  PUBLISH_STATUS,
  BATTERY_STATUS,
  LEASE_STATUS
} from "@/js/constant";
// import { timeToDate } from "@/utils/date-format";
export default {
  data() {
    return {
      flag:false,
      searchForm: {
        haiLiDeviceInfo: "", // 设备编号
        productNo: "", // 产品型号
        status: "", // 工作状态
        workStatus: "", // 工作状态
        bmsType: "", // BMS类型
        activityStatus: "", // 激活状态
        batteryStatus: "", // 电池状态
        positionStatus: "", //定位状态
        leaseStatus: "", //租赁状态
        switchStatus: "", //放电开关
        loanReceiptNo: "", //借据编号
        unitNo: "" //资金方编号
      },
      agentNo: "", //代理
      PRODUCT_TYPE,
      STATUS,
      WORK_STATUS,
      BMS_BOARD_TYPE,
      ACTIVITY_STATUS,
      BATTERY_STATUS,
      POSITION_STATUS,
      PUBLISH_STATUS,
      LEASE_STATUS,
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      /**选择的设备 */
      selectList: [],
      // 弹出层
      dialogVisible: false,
      loading: false,
      objectNos: "", //对象编号
      props: {
        value: "number",
        label: "name",
        children: "children",
        checkStrictly: true
      },
      angetProps: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      defaultProps: {
        value: "number",
        children: "children",
        label: "name"
      },
      treeData: [],
      objectData: []
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormWorkStatus.blur()
        _this.$refs.searchFormStatus.blur()
        _this.$refs.searchFormBmsType.blur()
        _this.$refs.searchFormActivityStatus.blur()
        _this.$refs.searchFormBatteryStatus.blur()
        _this.$refs.searchFormPositionStatus.blur()
        _this.$refs.searchFormLeaseStatus.blur()
        _this.$refs.searchFormSwitchStatus.blur()
         _this.$refs.product_no.blur()
        _this.onSearch()
      }
    };
    this.queryUnitLoanReceiptTree();
  },
  methods: {
    handleNodeClick(data) {
      this.searchForm.unitNo = "";
      this.searchForm.loanReceiptNo = "";
      if (data.number && data.level == 1) {
        this.searchForm.unitNo = data.number;
      }
      if (data.number && data.level == 2) {
        this.searchForm.loanReceiptNo = data.number;
      }
      if (data.number == "") {
        this.searchForm.unitNo = "";
        this.searchForm.loanReceiptNo = "";
      }
      this.getLoanList();
    },
    async getLoanList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      params.selectUnitNo = this.agentNo[this.agentNo.length - 1];
      const res = await getLoanList(params);
      console.log(res,'getLoanList');
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    async queryUnitLoanReceiptTree() {
      const res = await queryUnitLoanReceiptTree();
      this.objectData = JSON.parse(JSON.stringify(res.ldata));
      this.treeData = JSON.parse(JSON.stringify(res.ldata));
      this.flag = res.mdata.noDistribution;
      const newTree= [
        {
          children: [],
          name: "全部",
          number: "",
          level: 1
        }
      ]
      if(this.flag){
        newTree.push({
          children: [],
          name: "未分配",
          number: "-1",
          level: 1
        });
      }
      this.treeData = [...newTree,...this.treeData]
      //如果有未分配，走上面；没有未分配，走下面，随便传个unitNo
      if(this.treeData[0]){//没有数据不查询
        this.searchForm.unitNo = this.treeData[0].number;
        this.getLoanList();
      }

    },
    onSearch() {
      this.beginIndex = 1;
      this.getLoanList();
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getLoanList();
    },
    /** 选择 */
    handleSelectionChange(row) {
      this.selectList = row;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**打开弹窗-分配设备 */
    disEquipment() {
      if (!this.selectList.length) {
        this.$message.error("请选择设备");
        return;
      }
      this.dialogVisible = true;
    },
    async distributeBattery() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (!this.objectNos.length) {
        this.$message.error("请选择分配对象");
        return;
      }
      const params = {
        deviceList: JSON.stringify(this.selectList.map(el => el.haiLiDeviceNo))
      };
      if (this.objectNos.length == 1) {
        params.unitNo = this.objectNos[0];
      } else {
        params.loanReceiptNo = this.objectNos[1];
      }
      try {
        await createDeviceRelationList(params);
        this.dialogVisible = false;
        this.loading = false;
        this.$message.success("分配成功");
        this.onSearch();
      } catch (error) {
        this.loading = false;
      }
    }
  },
  components: {
    AgentTree,
    ProductNo
  }
};
</script>

<style scoped lang="less">
.battery-wrap {
  display: flex;
  padding-bottom: 80px;
  .tree {
    width: 15%;
    border-right: 1px solid #dcdfe6;
  }
  .content {
    width: 85%;
    padding-left: 20px;
  }
  /deep/ .el-tree-node__label {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
